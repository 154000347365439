import type { InternalAxiosRequestConfig } from 'axios';
import { getCookie } from 'typescript-cookie';

import { SESSION_KEY_COOKIE } from '$lib/app/constants/cookies';

export const addSessionKey = (config: InternalAxiosRequestConfig) => {
	if (config.headers) {
		config.headers.sessionKey = getCookie(SESSION_KEY_COOKIE);
	}

	return config;
};
