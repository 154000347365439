import { init, handleErrorWithSentry } from '@sentry/sveltekit';

import { backendInstance, strapiInstance } from '$lib/axios';
import { addSessionKey } from '$lib/axios/client';

import { PUBLIC_SENTRY_URL, PUBLIC_STAND } from '$env/static/public';

init({
	dsn: PUBLIC_SENTRY_URL,
	environment: PUBLIC_STAND,
	tracesSampleRate: 1.0,
	release: `gamegram@${__APP_VERSION__}`,
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
});

backendInstance.interceptors.request.use(addSessionKey);
strapiInstance.interceptors.request.use(addSessionKey);

export const handleError = handleErrorWithSentry();
